import Tracker from "@openreplay/tracker";
import trackerAssist from '@openreplay/tracker-assist'

const projectKey = import.meta.env.VITE_OR_KEY || "";
const ingestPoint = import.meta.env.VITE_OR_INGEST || "";
const isLocal = import.meta.env.VITE_LOCAL === "true";

if (globalThis.$or === undefined && !isLocal) {
    const tracker = new Tracker({
        projectKey,
        ingestPoint,
        // __DISABLE_SECURE_MODE: isLocal,
    });

    tracker.use(trackerAssist());
    tracker.start()
        .catch(console.error);

    globalThis.$or = tracker;
}
