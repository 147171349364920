import { OperationStatus } from "../enums/operation-status.enum.ts";
import { WalletType } from "../enums/wallet-type.enum.ts";
import { Currency } from "../enums/currency.enum.ts";
import { OperationSystemStatus } from "../enums/operation-system-status.enum.ts";
import { SYS_STATUS_DESCRIPTION } from "../constants/sys-status.constant.ts";
import {
	BNB_ASSET,
	BSC_BEP20_ASSET,
	TON_ASSET,
	TON_JETTON_ASSET,
	TRON_TRC20_ASSET,
	TRX_ASSET,
} from "../constants/currency.constant.ts";
import { PayoutAddressStatus } from "../enums/payout-address-status.enum.ts";

export const toShort = (
	text: string | undefined,
	firstSymbols = 15,
	lastSymbols = 6,
	disableSymbol = false,
): string => {
	if (text === undefined) {
		return "";
	}
	if (text.length <= 8) {
		return text;
	}
	const symbol = disableSymbol ? "" : "...";
	return (
		text.substr(0, firstSymbols) +
		symbol +
		text.substr(text.length - lastSymbols)
	);
};

export const toShort2 = (
	text: string | undefined | null,
	firstSymbols = 6,
	lastSymbols = 6,
	disableSymb = false,
): string => {
	if (!text) {
		return "";
	}

	if (firstSymbols >= text.length || lastSymbols >= text.length) {
		return text;
	}

	const symb: string = disableSymb ? "" : "...";
	return `${text.substring(0, firstSymbols)}${symb}${text.substring(text.length - lastSymbols)}`;
};

export const statusColor = (status: OperationStatus | undefined): string => {
	switch (status) {
		case OperationStatus.SUCCESS:
			return "bg-emerald-100 text-emerald-600";
		case OperationStatus.EXPIRED:
		case OperationStatus.CANCELED:
		case OperationStatus.ERROR:
		case OperationStatus.UNKNOWN:
			return "bg-rose-100 text-rose-600";
		case OperationStatus.PENDING:
			return "bg-amber-100 text-amber-600";
		case OperationStatus.EXTENDED:
			return "bg-orange-100 text-orange-600";
		case OperationStatus.PROCESSED:
			return "bg-indigo-100 text-indigo-600";
		default:
			return "bg-slate-100 text-slate-500";
	}
};

export const payoutAddressStatusColor = (
	status: PayoutAddressStatus | undefined,
): string => {
	switch (status) {
		case PayoutAddressStatus.APPROVED:
			return "bg-emerald-100 text-emerald-600";
		case PayoutAddressStatus.DISCARDED:
			return "bg-rose-100 text-rose-600";
		case PayoutAddressStatus.AWAITED:
			return "bg-amber-100 text-amber-600";
		default:
			return "bg-slate-100 text-slate-500";
	}
};

export const getFeeCurrency = (type: WalletType | undefined): Currency => {
	switch (type) {
		case WalletType.BSC:
			return Currency.BNB;
		case WalletType.TRON:
			return Currency.TRX;
		default:
			return Currency.NONE;
	}
};

export const getStateByStatus = (
	status: OperationSystemStatus | undefined,
): string => {
	if (!status) {
		return SYS_STATUS_DESCRIPTION.UNKNOWN;
	}
	return SYS_STATUS_DESCRIPTION[status];
};

export const allowRestartPayment = (
	status: OperationSystemStatus | undefined,
): boolean => {
	if (!status) {
		return false;
	}

	switch (status) {
		case OperationSystemStatus.IN_CHECK_PAYMENT:
		case OperationSystemStatus.WAIT_FEE:
		case OperationSystemStatus.IN_ACTIVATE_PAYMENT:
		case OperationSystemStatus.WAIT_SEND:
		case OperationSystemStatus.IN_SEND_PAYMENT:
			return true;
		default:
			return false;
	}
};

export const allowIPaidPayment = (
	status: OperationSystemStatus | undefined,
): boolean => {
	if (!status) {
		return false;
	}

	switch (status) {
		// в этом случае проверяем платеж так же как если бы нажали на кнопку я оплатил
		case OperationSystemStatus.WAIT_PAYMENT:
			return true;
		default:
			return false;
	}
};

export const openInNewTab = (url: string) => {
	window.open(url, "_blank", "noopener,noreferrer");
};

export const getTrustWalletLingByCurrency = (
	currency?: Currency,
	address?: string,
	amount?: string,
): string | undefined => {
	// trust://send?asset=c20000714_t0x55d398326f99059fF775485246999027B3197955&address=0x79cAED750faE386f19Df402D7a4dd70B5FA0Cd4b&amount=1
	// c20000714_t - prefix
	// c - coin
	// t - token
	// 0x55d398326f99059fF775485246999027B3197955 - contract address
	// 0x79cAED750faE386f19Df402D7a4dd70B5FA0Cd4b - address to send
	// amount - amount to send
	// trust://send?asset=c195_tTR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t&address=TYcxDELv3wUQeG6zCEVySq2398XriFj2ea&amount=1

	// искать тут https://github.com/trustwallet/assets/tree/master/blockchains/tron/assets
	// или тут https://developer.trustwallet.com/developer/listing-new-assets/universal_asset_id
	switch (currency) {
		case Currency.BNB:
			return `trust://send?asset=${BNB_ASSET}&address=${address}&amount=${amount}`;
		case Currency.BEP20:
			return `trust://send?asset=${BSC_BEP20_ASSET}&address=${address}&amount=${amount}`;
		case Currency.TRX:
			return `trust://send?asset=${TRX_ASSET}&address=${address}&amount=${amount}`;
		case Currency.TRC20:
			return `trust://send?asset=${TRON_TRC20_ASSET}&address=${address}&amount=${amount}`;
		case Currency.TON:
			return `trust://send?asset=${TON_ASSET}&address=${address}&amount=${amount}`;
		case Currency.JETTON:
			return `trust://send?asset=${TON_JETTON_ASSET}&address=${address}&amount=${amount}`;
	}
};
