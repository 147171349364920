export enum OperationStatus {
    NEW = 'NEW',
    PENDING = 'PENDING',
    PROCESSED = 'PROCESSED',
    SUCCESS = 'SUCCESS',
    EXPIRED = 'EXPIRED',
    ERROR = 'ERROR',
    UNKNOWN = 'UNKNOWN',
    EXTENDED = 'EXTENDED',
    CANCELED = 'CANCELED'
}
